import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../styles/pages/raiBotPage.css';

const RAIBotPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const staticResponses = {
    hi: "Hello! I'm the RAI Bot, here to help you with questions about the Resident Assessment Manual. What would you like to know?",
    hello: "Hi there! I'm the RAI Bot, ready to assist you with questions about the Resident Assessment Manual. How can I help?",
    hey: "Hello! I'm here to help you understand the Resident Assessment Manual better. What questions do you have?",
    "what do you do": "I'm an AI assistant specialized in answering questions about the Resident Assessment Manual. I can help explain concepts, clarify requirements, and provide guidance on RAI-related topics. Feel free to ask me anything about the manual!",
    "what can you do": "I'm designed to help you understand the Resident Assessment Manual. I can answer questions about assessments, coding, requirements, and other RAI-related topics. What would you like to learn more about?"
  };

  const headerText = isActive 
    ? "RAI Bot" 
    : "Ask me anything about the Resident Assessment Manual";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    if (!isActive) {
      setIsActive(true);
    }

    const userMessageLower = inputMessage.toLowerCase().trim();
    const userMessage = { text: inputMessage, sender: 'user' };
    setMessages(prev => [...prev, userMessage]);

    // Check for static responses
    if (staticResponses[userMessageLower]) {
      setMessages(prev => [...prev, { 
        text: staticResponses[userMessageLower], 
        sender: 'bot' 
      }]);
      setInputMessage('');
      return;
    }

    // Continue with API call for non-static responses
    setIsLoading(true);
    try {
      const response = await fetch('http://localhost:5000/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: inputMessage }),
      });

      const data = await response.json();
      const botMessage = { text: data.response, sender: 'bot' };
      setMessages(prev => [...prev, botMessage]);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { 
        text: 'Sorry, there was an error processing your request.', 
        sender: 'bot' 
      };
      setMessages(prev => [...prev, errorMessage]);
    }

    setIsLoading(false);
    setInputMessage('');
  };

  return (
    <div className="content rai-bot-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className={`text-center mb-4 ${isActive ? 'active' : ''}`}>
              {headerText}
            </h1>
            
            <div className={`chat-container ${isActive ? 'active' : ''}`}>
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  {message.text}
                </div>
              ))}
              {isLoading && (
                <div className="message bot loading">
                  Thinking...
                </div>
              )}
            </div>

            <div className={`message-form-wrapper ${isActive ? 'active' : ''}`}>
              <Form onSubmit={handleSubmit} className="message-form">
                <Form.Group className="d-flex">
                  <Form.Control
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Ask a question..."
                    disabled={isLoading}
                  />
                  <Button type="submit" disabled={isLoading}>
                    Send
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RAIBotPage; 