import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';
import NavHeader from './components/NavHeader';
import HomePage from './pages/HomePage';
import CoveragePage from './pages/CoveragePage';
import TeamPage from './pages/TeamPage';
import TeamMemberPage from './pages/TeamMemberPage';  
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RAIBotPage from './pages/RAIBotPage';


const App = () => (
  <Router>
    <NavHeader />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/coverage" element={<CoveragePage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/team/:memberId" element={<TeamMemberPage />} />
      <Route path="/rai-bot" element={<RAIBotPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    </Routes>
  </Router>
);

export default App;
