// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Main landing page for website.

import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/pages/homePage.css'
import home from '../assets/homepage-img.png'


function HomePage() {
  const calendlyLink = "https://calendly.com/qatalyst-health";

  return (
    <div className="content home-page">
      <Container>
        <Row xs={1} md={2}>
          <Col md={4} lg={6}>
            <div className="home-text-container">
              <p><span className="heading-txt title">Save Time.<br />
              <span style={{color: "#af9867", fontWeight: 'bold'}}>Increase Revenue.</span></span></p>
              
              <p><span className="subheading-txt">
                By integrating into your EMR, our software digitizes your reimbursement process and automatically fills out relevant Medicare and Medicaid reimbursement forms.
              </span></p>

              <div className='btn-container'>
                <a href={calendlyLink} target="_blank" rel="noopener noreferrer">
                  <button className="book-btn">
                    Book a call
                  </button>
                </a>
              </div>
            </div>
          </Col>

          <Col md={8} lg={6}>
            <img src={home} alt="main right" className="image-class" />
          </Col>
        </Row>
      </Container>
      <footer className="footer">
        <Link to="/privacy-policy" className="privacy-link">Privacy Policy</Link>
      </footer>
    </div>
  );
}

export default HomePage;


