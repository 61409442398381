// Copyright 2024 Qatalyst Health. All rights reserved.
// Login page for website.

import React, { useEffect } from 'react';

function LoginPage() {
    useEffect(() => {
        window.open('https://api.qatalysthealth.com', '_blank');
        window.history.back();
    }, []);

    return null;
}

export default LoginPage; 