// Copyright 2024 Qatalyst Health. All Rights Reserved.
// Privacy Policy page for website.

import React from 'react';
import Container from 'react-bootstrap/Container';
import '../styles/pages/privacyPolicyPage.css';

function PrivacyPolicyPage() {
  return (
    <div className="content privacy-policy-page">
      <Container>
        <h1 className="privacy-title">Privacy Statement</h1>
        <article className="privacy-content">
          <p className="effective-date">Effective Date: 1/23/2025</p>

          <section className="toc">
            <h2>Table of Contents</h2>
            <ul>
              <li><a href="#introduction">Introduction</a></li>
              <li><a href="#data-protection">Data Protection Officer</a></li>
              <li><a href="#collect-info">How we collect and use (process) your personal information</a></li>
              <li><a href="#website-use">Use of the Qatalysthealth.com website</a></li>
              <li><a href="#cookies">Cookies and tracking technologies</a></li>
              <li><a href="#services">Use of the Qatalyst services</a></li>
              <li><a href="#third-parties">When and how we share information with third parties</a></li>
              <li><a href="#us-transfer">Transferring personal data to the U.S.</a></li>
              <li><a href="#rights">Data Subject rights</a></li>
              <li><a href="#security">Security of your information</a></li>
              <li><a href="#storage">Data storage and retention</a></li>
              <li><a href="#questions">Questions, concerns, or complaints</a></li>
            </ul>
          </section>

          <section id="introduction">
            <h2>Introduction</h2>
            <p>Qatalyst Health ("Qatalyst") is a healthcare technology company that provides software as a service (SaaS) to nursing facilities. Our platform integrates with electronic health records (EHR) to automate the reimbursement process for Medicare and Medicaid. The purpose of this Board Charter is to define the composition, roles, and responsibilities of the Board of Directors ("the Board") of Qatalyst, ensuring effective governance and alignment with the company's mission.</p>
            <p>We understand that you are aware of and care about your own personal privacy interests, and we take that seriously. This Privacy Notice describes Qatalyst's policies and practices regarding its collection and use of your personal data, and sets forth your privacy rights. We recognize that information privacy is an ongoing responsibility, and so we will from time to time update this Privacy Notice as we undertake new personal data practices or adopt new privacy policies.</p>
          </section>

          <section id="data-protection">
            <h2>Data Protection Officer</h2>
            <p>Qatalyst Health is headquartered in Columbia, SC in the United States. Qatalyst has appointed an internal data protection officer for you to contact if you have any questions or concerns about Qatalyst's personal data policies or practices. If you would like to exercise your privacy rights, please direct your query to Qatalyst's data protection officer. Qatalyst's data protection officer's name and contact information are as follows:</p>
            <p>Brandon Mendez<br />
            VP of Operations<br />
            Qatalyst Health<br />
            711 Saluda Ave<br />
            Columbia, SC 29205<br />
            brandonmendez@qatalysthealth.com</p>
          </section>

          <section id="collect-info">
            <h2>How we collect and use (process) your personal information</h2>
            <p>Qatalyst collects personal information about its website visitors and customers. With a few exceptions, this information is generally limited to:</p>
            <ul>
              <li>name</li>
              <li>job title</li>
              <li>employer name</li>
              <li>work address</li>
              <li>work email</li>
              <li>work phone number</li>
            </ul>
            <p>We use this information to provide prospects and customers with services.</p>
            <p>We do not sell personal information to anyone and only share it with third parties who are facilitating the delivery of our services.</p>
            <p>From time to time, Qatalyst receives personal information about individuals from third parties. Typically, information collected from third parties will include further details on your employer or industry. We may also collect your personal data from a third party website (e.g. LinkedIn)</p>
          </section>

          <section id="website-use">
            <h2>Use of the Qatalysthealth.com website</h2>
            <p>As is true of most other websites, Qatalyst's website collects certain information automatically and stores it in log files. The information may include internet protocol (IP) addresses, the region or general location where your computer or device is accessing the internet, browser type, operating system and other usage information about the use of Qatalyst's website, including a history of the pages you view. We use this information to help us design our site to better suit our users' needs. We may also use your IP address to help diagnose problems with our server and to administer our website, analyze trends, track visitor movements, and gather broad demographic information that assists us in identifying visitor preferences.</p>
            <p>Qatalyst has a legitimate interest in understanding how members, customers and potential customers use its website. This assists Qatalyst with providing more relevant products and services, with communicating value to our sponsors and corporate members, and with providing appropriate staffing to meet member and customer needs.</p>
          </section>

          <section id="cookies">
            <h2>Cookies and tracking technologies</h2>
            <p>Qatalyst makes available a comprehensive <span className="cookie-notice">COOKIE NOTICE</span> that describes the cookies and tracking technologies used on Qatalyst website and provides information on how users can accept or reject them. To view the notice, just click <span className="cookie-notice">COOKIE NOTICE</span>.</p>
          </section>

          <section id="services">
            <h2>Use of the Qatalyst services</h2>
            <p>The personal information Qatalyst collects from you is stored in one or more databases hosted by third parties located in the United States. These third parties do not use or have access to your personal information for any purpose other than cloud storage and retrieval. On occasion, Qatalyst engages third parties to send information to you, including information about our products, services, and events.</p>
            <p>We do not otherwise reveal your personal data to non-Qatalyst persons or businesses for their independent use unless: (1) you request or authorize it; (2) it's in connection with Qatalyst-hosted and Qatalyst co-sponsored conferences as described above; (3) the information is provided to comply with the law (for example, compelled by law enforcement to comply with a search warrant, subpoena, or court order), enforce an agreement we have with you, or to protect our rights, property or safety, or the rights, property or safety of our employees or others; (4) the information is provided to our agents, vendors or service providers who perform functions on our behalf; (5) to address emergencies or acts of God; or (6) to address disputes, claims, or to persons demonstrating legal authority to act on your behalf. We may also gather aggregated data about our services and website visitors and disclose the results of such aggregated (but not personally identifiable) information to our partners, service providers, advertisers, and/or other third parties for marketing or promotional purposes.</p>
            <p>The Qatalyst website connects with third party services such as Facebook, LinkedIn, Twitter and others. If you choose to share information from the Qatalyst website through these services, you should review the privacy policy of that service. If you are a member of a third party service, the aforementioned connections may allow that service to connect your visit to our site to your personal data.</p>
          </section>

          <section id="third-parties">
            <h2>When and how we share information with third parties</h2>
            <p>Qatalyst has its headquarters in the United States. Information we collect about you will be processed in the United States. By using Qatalyst's services, you acknowledge that your personal information will be processed in the United States. The United States has not sought nor received a finding of "adequacy" from the European Union under Article 45 of the GDPR. Pursuant to Article 46 of the GDPR, Qatalyst is providing for appropriate safeguards by entering binding, standard data protection clauses, enforceable by data subjects in the EEA and the UK. These clauses have been enhanced based on the guidance of the European Data Protection Board and will be updated when the new draft model clauses are approved.</p>
            <p>Depending on the circumstance, Qatalyst also collects and transfers to the U.S. personal data with consent; to perform a contract with you; or to fulfill a compelling legitimate interest of Qatalyst in a manner that does not outweigh your rights and freedoms. Qatalyst endeavors to apply suitable safeguards to protect the privacy and security of your personal data and to use it only consistent with your relationship with Qatalyst and the practices described in this Privacy Statement. Qatalyst also enters into data processing agreements and model clauses with its vendors whenever feasible and appropriate. Since it was founded, Qatalyst has received zero government requests for information.</p>
            <p>For more information or if you have any questions, please contact us at privacy@Qatalyst.com</p>
          </section>

          <section id="us-transfer">
            <h2>Transferring personal data to the U.S.</h2>
            <p>The European Union's General Data Protection Regulation (GDPR) and other countries' privacy laws provide certain rights for data subjects. Data Subject rights under GDPR include the following:</p>
            <ul>
              <li>Right to be informed</li>
              <li>Right of access</li>
              <li>Right to rectification</li>
              <li>Right to erasure</li>
              <li>Right to restrict processing</li>
              <li>Right of data portability</li>
              <li>Right to object</li>
              <li>Rights related to automated decision making including profiling</li>
            </ul>
            <p>This Privacy Notice is intended to provide you with information about what personal data Qatalyst collects about you and how it is used.</p>
            <p>If you wish to confirm that Qatalyst is processing your personal data, or to have access to the personal data Qatalyst may have about you, please contact us.</p>
            <p>You may also request information about: the purpose of the processing; the categories of personal data concerned; who else outside Qatalyst might have received the data from Qatalyst; what the source of the information was (if you didn't provide it directly to Qatalyst); and how long it will be stored. You have a right to correct (rectify) the record of your personal data maintained by Qatalyst if it is inaccurate. You may request that Qatalyst erase that data or cease processing it, subject to certain exceptions. You may also request that Qatalyst cease using your data for direct marketing purposes. In many countries, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how Qatalyst processes your personal data. When technically feasible, Qatalyst will—at your request—provide your personal data to you.</p>
            <p>Reasonable access to your personal data will be provided at no cost. If access cannot be provided within a reasonable time frame, Qatalyst will provide you with a date when the information will be provided. If for some reason access is denied, Qatalyst will provide an explanation as to why access has been denied.</p>
            <p>For questions or complaints concerning the processing of your personal data, you can email us at privacy@Qatalyst.com. Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nation's data protection authority.</p>
          </section>

          <section id="rights">
            <h2>Data Subject rights</h2>
            <p>Your personal data is stored by the Qatalyst on its servers, and on the servers of the cloud-based database management services the Qatalyst engages, located in the United States. The Qatalyst retains service data for the duration of the customer's business relationship with the Qatalyst and for a period of time thereafter, to analyze the data for Qatalyst's own operations, and for historical and archiving purposes associated with Qatalyst's services. Qatalyst retains prospect data until such time as it no longer has business value and is purged from Qatalyst systems. All personal data that Qatalyst controls may be deleted upon verified request from Data Subjects or their authorized agents. For more information on where and how long your personal data is stored, and for more information on your rights of erasure and portability, please contact us at: privacy@Qatalyst.com</p>
          </section>

          <section id="security">
            <h2>Security of your information</h2>
            <p>We do not knowingly attempt to solicit or receive information from children.</p>
          </section>

          <section id="storage">
            <h2>Data storage and retention</h2>
            <p>If you have questions, concerns, complaints, or would like to exercise your rights, please contact us at:</p>
            <p>Brandon Mendez<br />
            VP of Operations<br />
            Qatalyst Health<br />
            711 Saluda Ave<br />
            Columbia, SC 29205<br />
            brandonmendez@qatalysthealth.com</p>
          </section>

          <section id="questions">
            <h2>Questions, concerns, or complaints</h2>
            <p>If you have questions, concerns, complaints, or would like to exercise your rights, please contact us at:</p>
            <p>Brandon Mendez<br />
            VP of Operations<br />
            Qatalyst Health<br />
            711 Saluda Ave<br />
            Columbia, SC 29205<br />
            brandonmendez@qatalysthealth.com</p>
          </section>
        </article>
      </Container>
    </div>
  );
}

export default PrivacyPolicyPage; 